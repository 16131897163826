import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { muscleGroups } from "../../common/muscle-groups";
import { create } from "../../utilities/api";

const AddExercise = () => {
  const history = useHistory();

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  const formLabelColumnViewpoint = {
    sm: 12,
    md: 3,
  };

  const createExerciseSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the exercise name"),
    type: Yup.string().required("Please select the exercise type"),
    // movementType: Yup.string().required("Please select the movement type"),
    primaryMuscleGroup: Yup.string().required("Please select the primary muscle group"),
    lateralType: Yup.string().required("Please select the lateral type"),
    e1rm: Yup.string().required("Please select the E1RM"),
    videoLink: Yup.string().url("Invalid url!"),
    movementType: Yup.string().when("type", {
      is: "Compound", // alternatively: (val) => val == true
      then: Yup.string().required("Please select the movement type"),
    }),
  });

  const createExerciseInitialValues = {
    name: "",
    type: "",
    movementType: "",
    primaryMuscleGroup: "",
    secondaryMuscleGroup: "",
    lateralType: "",
    e1rm: "",
    description: "",
    videoLink: "",
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const muscleGroups = [];
    if (values.secondaryMuscleGroups != null) {
      muscleGroups.push(
        ...values.secondaryMuscleGroups.map((mg) => ({
          id: mg.value,
          primary: "False",
        }))
      );
    }
    muscleGroups.push({ id: parseInt(values.primaryMuscleGroup), primary: "True" });

    const data = {
      exerciseName: values.name,
      exerciseType: values.type,
      muscleGroups: muscleGroups,
      unilateralBilateral: values.lateralType,
      E1RM: values.e1rm,
      description: values.description,
      videoLink: values.videoLink,
    };
    if (values.type === "Compound") {
      data.movementType = values.movementType;
    }

    create("/exercises", data)
      .then((res) => {
        // Handle api success and redirect them
        history.push("/exercises");
      })
      .catch((err) => {
        setErrors({ apiError: "There was a problem processing your request" });
        setSubmitting(false);
      });
  };

  const FormInput = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Control
              name={props.name}
              type="text"
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            />
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormTextAreaInput = (props) => {
    const error = props.errors[props.name];
    const [count, setCount] = useState(0);

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Control
              as="textarea"
              name={props.name}
              type="text"
              value={props.values[props.name]}
              onChange={(event) => {
                setCount(event.target.value.length);
                props.handleChange(event);
              }}
              isInvalid={error}
              maxlength="500"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="px-1">
          <Col>
            <div style={{ textAlign: "right", color: "gray" }}>{count}/500</div>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormExerciseTypeSelect = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select
              name={props.name}
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            >
              <option value="" disabled>
                Select exercise type
              </option>
              <option value="Compound">Compound</option>
              <option value="Isolation">Isolation</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const selectMuscleGroups = (firstOption) => {
    const results = [];

    results.push(
      <option value="" disabled>
        {firstOption}
      </option>
    );

    for (const mg of muscleGroups) {
      results.push(<option value={mg.id}>{mg.name}</option>);
    }

    return results;
  };

  const FormPrimaryGroupSelect = (props) => {
    const error = props.errors[props.name];
    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select
              name={props.name}
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            >
              {selectMuscleGroups("Select the primary muscle group")}
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormSecondryGroupSelect = (props) => {
    const error = props.errors[props.label];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Select
              isMulti
              name={props.name}
              options={muscleGroups.map((mg) => ({ value: mg.id, label: mg.name }))}
              label={props.label}
              value={props.values[props.name]}
              onChange={(value) => props.handleChange(props.name, value)}
              isInvalid={error}
            />
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormLateralTypeSelect = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select
              name={props.name}
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            >
              <option value="" disabled>
                Select the Lateral type
              </option>
              <option value="Unilateral">Unilateral</option>
              <option value="Bilateral">Bilateral</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormE1rmSelect = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select
              name={props.name}
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormMovementTypeSelect = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select
              name={props.name}
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            >
              <option value="" disabled>
                Select movement type
              </option>
              <option value="Squat">Squat</option>
              <option value="Hinge">Hinge</option>
              <option value="Lunge">Lunge</option>
              <option value="Lower Push">Lower Push</option>
              <option value="Lower Pull">Lower Pull</option>
              <option value="Horizontal Push">Horizontal Push</option>
              <option value="Horizontal Pull">Horizontal Pull</option>
              <option value="Vertical Push">Vertical Push</option>
              <option value="Vertical Pull">Vertical Pull</option>
              <option value="Core">Core</option>
              <option value="Carry">Carry</option>
              <option value="Torso Flexion">Torso Flexion</option>
              <option value="Elbow Flexion">Elbow Flexion</option>
              <option value="Wrist Flexion">Wrist Flexion</option>
              <option value="Upper Push">Upper Push</option>
              <option value="Upper Pull">Upper Pull</option>
              <option value="Abdominals">Abdominals</option>
              <option value="Cardio">Cardio</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <>
      <Container fluid="md">
        <div className="p-5">
          <Row>
            <Col {...columnsViewpoint}>
              <h1>Add Exercise</h1>
            </Col>
          </Row>
          <Row>
            <Col {...columnsViewpoint}>
              <Card className="shadow">
                <Card.Body>
                  <Formik
                    validateOnChange={false}
                    validationOnBlur={false}
                    initialValues={createExerciseInitialValues}
                    validationSchema={createExerciseSchema}
                    onSubmit={(v, { setSubmitting, setErrors }) => handleSubmit(v, { setSubmitting, setErrors })}
                  >
                    {({ handleSubmit, handleChange, setFieldValue, values, errors, isSubmitting }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <FormInput
                          label="Exercise Name:"
                          name="name"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormExerciseTypeSelect
                          label="Exercise Type:"
                          name="type"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        {values.type === "Compound" && (
                          <FormMovementTypeSelect
                            label="Movement Type:"
                            name="movementType"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                        )}
                        <FormPrimaryGroupSelect
                          label="Primary Muscle Group:"
                          name="primaryMuscleGroup"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormSecondryGroupSelect
                          label="Secondry Muscle Group:"
                          name="secondaryMuscleGroups"
                          values={values}
                          handleChange={setFieldValue}
                          errors={errors}
                        />
                        <FormLateralTypeSelect
                          label="Lateral Type:"
                          name="lateralType"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormE1rmSelect
                          label="E1RM:"
                          name="e1rm"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormTextAreaInput
                          label="Description:"
                          name="description"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <FormInput
                          label="Video Link:"
                          name="videoLink"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <Row>
                          <Col {...formLabelColumnViewpoint}></Col>
                          <Col>
                            <div className="pt-1 ps-1">
                              <Button className="me-1 form-button" type="submit" disabled={isSubmitting}>
                                Add
                              </Button>
                              <Link to={"/exercises"} className="btn btn-primary form-button">
                                Cancel
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default AddExercise;
