export const muscleGroups = [
  {
    id: 1,
    name: "Gluteal Complex",
    bodyArea: "Lower Body",
  },
  {
    id: 2,
    name: "Quadriceps",
    bodyArea: "Lower Body",
  },
  {
    id: 3,
    name: "Hamstrings",
    bodyArea: "Lower Body",
  },
  {
    id: 4,
    name: "Calves",
    bodyArea: "Lower Body",
  },
  {
    id: 5,
    name: "Deltoids",
    bodyArea: "Upper Body",
  },
  {
    id: 6,
    name: "Biceps",
    bodyArea: "Upper Body",
  },
  {
    id: 7,
    name: "Triceps",
    bodyArea: "Upper Body",
  },
  {
    id: 8,
    name: "Forearms",
    bodyArea: "Upper Body",
  },
  {
    id: 9,
    name: "Pectorals",
    bodyArea: "Upper Body",
  },
  {
    id: 10,
    name: "Trapezius",
    bodyArea: "Upper Body",
  },
  {
    id: 11,
    name: "Latissimus Dorsi",
    bodyArea: "Upper Body",
  },
  {
    id: 12,
    name: "Abdominals",
    bodyArea: "Anterior Trunk",
  },
  {
    id: 13,
    name: "Erector Spinae",
    bodyArea: "Posterior Trunk",
  },
  {
    id: 14,
    name: "Upper Back",
    bodyArea: "Posterior Trunk",
  },
  {
    id: 15,
    name: "Cardio",
    bodyArea: "Cardio",
  },
];
